import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as GPPPlus } from "assets/icons/gpp-plus.svg";
import BoxWithPadding from "components/BoxWithPadding/BoxWithPadding";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import SMButtonWithIcon from "components/SMButton/SMButtonWithIcon";
import Maintitle from "components/SMTitles/MainTitle";
import SubTitle from "components/SMTitles/SubTitle";
import { TWO_FACTOR_AUTH } from "constants/auth.constants";
import { IUser } from "interfaces/user.interface";
import {
  getAuthUser,
  passWordReset,
  updateAuthUser,
} from "store/actions/auth/authActions";
import { ButtonAsLabelStyled, SubTitleStyled } from "styled/CommonStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import { ApplyMargin, StyledP } from "styled/StylesStyled";
import * as LOCAL_STORAGE from "utils/localStorage";

import { IRootState } from "store/reducers";
import { getAuthBaseUrl } from "utils/auth/auth.util";
import UserForm from "../zones/userView/components/UserForm";
import UserPreference from "./components/UserPreference";

export const UserProfileScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [authUser, setAuthUser] = useState<IUser>();
  const [showPasswordResetText, setShowPasswordResetText] =
    useState<boolean>(false);

  const authState = useSelector(
    (state: IRootState) => state.authReducerV1 ?? {},
  );

  const methods = useForm<IUser>({
    defaultValues: {
      ...authUser,
    },
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IUser> = (data: IUser) => {
    dispatch(updateAuthUser(data));
  };

  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  useEffect(() => {
    if (authState.user) {
      setAuthUser(authState.user);
      methods.reset(authState.user);
    }
  }, [authState.user, methods]);

  if (!authUser) {
    return <div />;
  }

  const changePassword = () => {
    // const url = getPasswordChangeUrl();
    // window.open(url, "_self");
    dispatch(passWordReset(() => setShowPasswordResetText(true)));
  };

  const handleTwofactorAuth = () => {
    const client_id = LOCAL_STORAGE.getClientId();

    const url = `${getAuthBaseUrl()}${TWO_FACTOR_AUTH}/?client_id=${client_id}`;
    window.open(url, "_self");
  };

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="User Profile Screen" />

      <BoxWithPadding pad={32}>
        <FormProvider {...methods}>
          <ApplyMargin bottom={24}>
            <Maintitle
              text={t("employee_profile")}
              showBack
              textBack={t("back")}
            />
          </ApplyMargin>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <BoxSubSectionStyled>
                <SubTitle text={t("employee_information")} />
                <BoxSubSectionContentStyled>
                  <UserForm user={authUser} onSubmit={onSubmit} />
                  <ApplyMargin top={44}>
                    <ButtonAsLabelStyled onClick={() => changePassword()}>
                      {t("Change Password")}
                    </ButtonAsLabelStyled>
                  </ApplyMargin>
                  {showPasswordResetText && (
                    <StyledP>
                      {t("you_will_recieve_an_email_to_reset_your_password")}
                    </StyledP>
                  )}
                  <ApplyMargin top={30}>
                    <SubTitleStyled
                      sx={{
                        textTransform: "uppercase",
                        background: "none",
                        boxShadow: "none",
                        paddingLeft: 0,
                      }}
                    >
                      {t("multi_factor_authentication_mfa")}
                    </SubTitleStyled>
                    <SMButtonWithIcon
                      text={t("manage_mfa")}
                      onClick={() => handleTwofactorAuth()}
                      startIcon={<GPPPlus />}
                      width={100}
                      border
                    />
                  </ApplyMargin>
                </BoxSubSectionContentStyled>
              </BoxSubSectionStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <BoxSubSectionStyled>
                <SubTitle text={t("preference")} />
                <BoxSubSectionContentStyled>
                  <UserPreference onSubmit={onSubmit} />
                </BoxSubSectionContentStyled>
              </BoxSubSectionStyled>
            </Grid>
          </Grid>
        </FormProvider>
      </BoxWithPadding>
    </>
  );
};

export default UserProfileScreen;
