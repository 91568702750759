import { Range } from "react-date-range";
import { endOfDay } from "date-fns";

import i18n from "../i18n/config";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

function joinDateStrings(
  t: number | Date | undefined,
  a: any[],
  s: string,
  locale: string,
) {
  function format(m: any) {
    const f = new Intl.DateTimeFormat(locale, m);
    return f.format(t);
  }
  return a.map(format).join(s);
}

export const formateDateToDDMMMYYYY = (
  date: string,
  locale: string,
): string => {
  const a = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
  return joinDateStrings(new Date(date), a, "-", locale);
};

export const localeMap: { [key: string]: string } = {
  en: "en-gb",
  de: "de-de",
  nb_private_customer: "nb",
  nb_sleep_research: "nb",
  nb_healthwelfare: "nb",
};

export const getLocale = () => {
  const lang: string = i18n.language || "en";
  return localeMap[lang];
};

export const getDateFormate = (lang: string) => {
  if (lang === "nb") {
    return "DD. MMMM";
  }
  return "MMMM DD";
};

export const secondsToTime = (secs: number) => {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);
  if (seconds > 30) {
    minutes += 1;
  }
  const obj = {
    h: hours,
    m: minutes,
  };
  return obj;
};

export const getMinuteString = (h: number, m: number) => {
  if (m > 0) {
    return `${m}<span style='word-spacing: -0.125em'>&nbsp</span>${i18n.t(
      "minutes_abbreviation_lc",
    )}`;
  }
  if (!h) {
    return `${m}<span style='word-spacing: -0.125em'>&nbsp</span>${i18n.t(
      "minutes_abbreviation_lc",
    )}`;
  }
  return "";
};

export const toIsoString = (date: Date) => {
  const pad = (num: number) => {
    return (num < 10 ? "0" : "") + num;
  };

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds(),
  )}`;
};

export const dateRangeGetEndDate = (range: Range): string => {
  if (
    range.endDate &&
    new Date(range.startDate as Date).getTime() ===
      new Date(range.endDate as Date).getTime()
  ) {
    return toIsoString(endOfDay(new Date(range.endDate)));
  }

  return range.endDate && new Date(range.endDate) < new Date()
    ? toIsoString(endOfDay(range.endDate))
    : toIsoString(new Date());
};

export const isoEquals = (iso1: string, iso2: string): boolean => {
  const datetime1 = moment(iso1, moment.ISO_8601, true);
  const datetime2 = moment(iso2, moment.ISO_8601, true);

  if (!datetime1.isValid() || !datetime2.isValid()) return false;

  return datetime1.isSame(datetime2);
};

export const useBrowserTimezone = () => {
  const [timezone, setTimezone] = useState<string>("");

  useEffect(() => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(browserTimezone);
  }, []);

  return timezone;
};

export const timesEquals = (time1: string, time2: string): boolean => {
  //the format might be both HH:mm or HH:mm:ss
  const format1 = time1.split(":").length === 3 ? "HH:mm:ss" : "HH:mm";
  const datetime1 = moment(time1, format1, true);
  const format2 = time2.split(":").length === 3 ? "HH:mm:ss" : "HH:mm";
  const datetime2 = moment(time2, format2, true);

  if (!datetime1.isValid() || !datetime2.isValid()) return false;

  return datetime1.isSame(datetime2);
};
