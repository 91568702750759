import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useTranslation } from "react-i18next";

import { ReactComponent as SortAscIcon } from "assets/icons/new/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/new/sort-desc.svg";
import { ReactComponent as UnsortedIcon } from "assets/icons/new/unsorted-icon.svg";
import i18n from "i18n/config";
import {
  EnhancedTableProps,
  NightReport,
  NightReportHeadCell,
  NightReportMetric,
} from "interfaces/nightReport.interface";
import { IconButtonStyled } from "styled/CommonStyled";

import useFixedHeader from "hooks/UseFixedHeader";
import { Order } from "interfaces/componentsTypes/tableComponentsTypes";
import { MouseEvent, MutableRefObject, useState } from "react";
import {
  TableHeadDataStyled,
  TableHeadRowStyled,
} from "./NightReportStyledComponents";
import NightReportTableRow from "./NightReportTableRow";

function descendingComparator(
  a: NightReport,
  b: NightReport,
  orderBy: keyof NightReport | "device_name" | "subject_id",
) {
  if (!orderBy) return 0;

  const finalValues = getComparatorValues(a, b, orderBy);

  const finalLeftVal = finalValues[0];
  const finalRightVal = finalValues[1];

  if (typeof finalLeftVal === "string" && typeof finalRightVal === "string") {
    return -finalLeftVal.localeCompare(finalRightVal);
  }

  if (finalLeftVal < finalRightVal) {
    return 1;
  }

  if (finalLeftVal > finalRightVal) {
    return -1;
  }
  return 0;
}

function getComparatorValues(
  a: NightReport,
  b: NightReport,
  orderBy: keyof NightReport | "device_name" | "subject_id",
) {
  switch (orderBy) {
    case "device_name":
      return [a["device"].name, b["device"].name];
    case "subject_id":
      return [a["subject"].identifier, b["subject"].identifier];
    case "baseline_night_count":
      return [a["baseline_night_count"], b["baseline_night_count"]];
    case "selected_session_is_in_progress":
      return [
        a["selected_session_is_in_progress"],
        b["selected_session_is_in_progress"],
      ];
  }
  return [
    (a[orderBy as keyof NightReport] as NightReportMetric)?.value,
    (b[orderBy as keyof NightReport] as NightReportMetric)?.value,
  ];
}

/**
 * Function to check the type of operation
 *
 * @returns `descendingComparator` function
 *
 */
function getComparator(
  order: Order,
  orderBy: keyof NightReport | "device_name" | "subject_id",
): (a: NightReport, b: NightReport) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const getHeadCells = (t: any) => {
  const headCells: NightReportHeadCell[] = [
    {
      id: "device_name",
      label: t("Device Name / Location"),
      hideSort: false,
      tooltip: t("night_report_device_name_info"),
      width: "15%",
    },
    {
      id: "subject_id",
      label: t("subject"),
      tooltip: t("night_report_subject_info"),
      width: "15%",
    },
    {
      id: "respiration_rate",
      label: t("Respiration Rate (rpm)"),
      tooltip: t("night_report_respiration_rate_info"),
      width: "10%",
    },
    {
      id: "movement",
      label: t("Movement"),
      tooltip: t("night_report_movement_info"),
      width: "10%",
    },
    {
      id: "time_asleep",
      label: t("time_asleep"),
      tooltip: t("night_report_time_asleep_info"),
      width: "20%",
    },
    {
      id: "sleep_score",
      label: t("Sleep Score"),
      tooltip: t("night_report_sleep_score_info"),
      width: "10%",
    },
    {
      id: "out_of_bed",
      label: t("Out of Bed"),
      tooltip: t("night_report_out_of_bed_info"),
      width: "10%",
    },
    {
      id: "selected_session_is_in_progress",
      label: t("Session in Progress"),
      tooltip: t("night_report_selected_session_is_in_progress_info"),
      width: "10%",
    },
    {
      id: "baseline_night_count",
      label: i18n.t("Baseline Nights"),
      tooltip: i18n.t("night_report_baseline_night_count_info"),
    },
  ];
  return headCells;
};

function SMTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: keyof NightReport) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const headCells: NightReportHeadCell[] = getHeadCells(t);
  const headerRef = props.headerRef;
  return (
    <TableHead component={"thead"} ref={headerRef}>
      <TableHeadRowStyled>
        {headCells.map((headCell: NightReportHeadCell) => (
          <TableCell
            key={headCell.label}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell?.width || "auto" }}
          >
            <TableHeadDataStyled
              onClick={
                !headCell.hideSort
                  ? createSortHandler(headCell.id as keyof NightReport)
                  : () => {}
              }
              cursordefault={headCell.hideSort ? 1 : 0}
              alignLeft={headCells.indexOf(headCell) <= 1 ? 1 : 0}
            >
              <Tooltip placement="top" title={headCell.tooltip || "dd"}>
                <div>
                  <div
                    className={
                      headCell.id === "baseline_night_count"
                        ? "print_css_baseline_night"
                        : ""
                    }
                  >
                    {" "}
                    {headCell.label}
                  </div>{" "}
                  <div className="sortIconParentDiv">
                    {" "}
                    {!headCell.hideSort && (
                      <IconButtonStyled padding={0} width="16px">
                        {orderBy === headCell.id ? (
                          order === "asc" ? (
                            <SortAscIcon />
                          ) : (
                            <SortDescIcon />
                          )
                        ) : (
                          <UnsortedIcon />
                        )}
                      </IconButtonStyled>
                    )}{" "}
                  </div>
                </div>
              </Tooltip>
            </TableHeadDataStyled>
          </TableCell>
        ))}
      </TableHeadRowStyled>
    </TableHead>
  );
}

export default function NightReportTable({
  reports,
  nightReportRowClick,
  prevWidth,
}: {
  reports: NightReport[];
  nightReportRowClick: (row: NightReport) => void;
  prevWidth: MutableRefObject<number>;
}) {
  const [order, setOrder] = useState<Order>("asc");

  const [orderBy, setOrderBy] = useState<
    keyof NightReport | "device_name" | "subject_id"
  >("device_name");

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof NightReport,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const customSortOrder: { [key: string]: number } = {
    PRESENCE_AND_SLEEP: 1,
    NO_SLEEP: 2,
    NO_PRESENCE: 2,
  };

  const sortedRows = reports
    .slice()
    .sort(getComparator(order, orderBy))
    .sort(
      (a: NightReport, b: NightReport) =>
        (customSortOrder[a.state] || 0) - customSortOrder[b.state] || 0,
    );

  const { headerRef, tableWrapperRef, lastChildRef } =
    useFixedHeader(prevWidth);

  return (
    <Box sx={{ width: "100%" }} ref={tableWrapperRef}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <SMTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headerRef={headerRef}
          />
          <TableBody>
            {sortedRows.map((row, index) => (
              <NightReportTableRow
                key={index}
                row={row}
                onClickRow={nightReportRowClick}
                lastChildRef={
                  index === sortedRows.length - 1 ? lastChildRef : null
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
