import { ITileSettings } from "@modules/monitor/DashboardScreen";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Avatar,
  Box,
  CardActions,
  CardContent,
  ClickAwayListener,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as BedIcon } from "assets/icons/new/bed.svg";
import { ReactComponent as FootprintIcon } from "assets/icons/new/footprint.svg";
import { ReactComponent as LungsIcon } from "assets/icons/new/lungs.svg";
import { IMonitor } from "interfaces/monitor.interface";
import { memo, MouseEvent, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MonitorCardStyled } from "styled/ContainerStylesStyled";
import {
  getDuration,
  getMonitorMovementText,
  getMonitorTileStatusIcon,
  getMonitorTileStatusText,
} from "utils/dashboard/dashboard.util";
import { secondsToTime } from "utils/date.util";
import { DeviationAlerts, DeviationMetric } from "../SubjectOverview";

const ErrorWrapper = styled("div")(() => ({
  "&>svg": {
    color: "rgb(165, 5, 32)",
    width: 28,
    height: 28,
    "@media print": {
      width: 12,
      height: 12,
    },
  },
}));

const RenderAlert = ({ metric }: { metric: DeviationMetric }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const tooltipText = `${t(metric.type)}: ${Math.abs(
    Math.round(metric.amount),
  )}% ${t(metric.isPositiveDeviation ? "above_average" : "below_average")}`;
  let Icon = <ErrorOutlineIcon />;
  switch (metric.type) {
    case "respiration_rate":
      Icon = <LungsIcon />;
      break;
    case "movement":
      Icon = <FootprintIcon />;
      break;
    case "time_asleep":
      Icon = <BedIcon />;
      break;
  }
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        title={tooltipText}
        placement="top"
        onClose={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        open={open}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          setOpen((prev) => !prev);
        }}
      >
        <ErrorWrapper>{Icon}</ErrorWrapper>
      </Tooltip>
    </ClickAwayListener>
  );
};

interface Iprops {
  data: IMonitor;
  onClick: (item: IMonitor) => void;
  deviationAlerts?: DeviationAlerts;
}
const LiveMonitorTile = ({ data, onClick, deviationAlerts }: Iprops) => {
  const { watch } = useFormContext<ITileSettings>();
  const { t } = useTranslation();
  const [epochText, setEpochText] = useState<string>("\u00A0");
  const [durationText, setDurationText] = useState<string>("\u00A0");

  // form watch
  const tileScale = watch("tileScale", 50);
  const showIcon = watch("showIcon");
  const showSubjectId = watch("showSubjectId");
  const showDeviceName = watch("showDeviceName");
  const showDuration = watch("showDuration");
  const showVitals = watch("showVitals");
  const showEpochTimestamp = watch("show_epoch_timestamp");
  const deviceNameFontsize = (32 * tileScale) / 100;
  const subjectFontSize = (32 * tileScale) / 100;
  const statusFontSize = (40 * tileScale) / 100;
  const avatarScale = (120 * tileScale) / 100;
  const footerFontSize = (23 * tileScale) / 100;
  const durationFontSize = (28 * tileScale) / 100;
  const cardHeaderPadding = (32 * tileScale) / 100;
  const cardActionPadding = (21 * tileScale) / 100;

  const isDisabled =
    data.state === "device_inactive" ||
    data.state === "device_offline" ||
    data.state === "device_sleep_tracking_disabled";

  const getDurationText = useCallback(() => {
    const duration = getDuration(data.state_changed_at);
    setDurationText(duration);
  }, [data.state_changed_at]);

  const getMonitorEpochTimestamp = useCallback(() => {
    if (data.latest_epoch_timestamp) {
      const now = new Date();
      const latestEpochTimestamp = new Date(data.latest_epoch_timestamp);
      const milisecDiff = now.getTime() - latestEpochTimestamp.getTime();
      const duration = secondsToTime(milisecDiff / 1000);
      const epoch = `${t("updated")} ${duration.h} ${t("hours")} ${
        duration.m
      } ${t("minutes")} ${t("ago")}`;
      setEpochText(epoch);
    } else {
      setEpochText("\u00A0");
    }
  }, [data.latest_epoch_timestamp, t]);

  useEffect(() => {
    getDurationText();
    getMonitorEpochTimestamp();
  }, [getDurationText, getMonitorEpochTimestamp]);

  return (
    <Box onClick={() => onClick(data)}>
      <Tooltip
        placement="top"
        title={
          !data.subject?.identifier
            ? t("no_care_receiver_associated_with_this_device")
            : ""
        }
      >
        <MonitorCardStyled
          square
          pointer={data.subject?.identifier ? 1 : 0}
          state={data.state}
        >
          <CardContent
            sx={{
              m: 0,
              p: 0,
              height: "auto",
              display: "flex",
            }}
            style={{
              paddingLeft: cardHeaderPadding,
              paddingRight: cardHeaderPadding,
              paddingTop: !showDeviceName
                ? cardHeaderPadding / 2
                : cardHeaderPadding,
              paddingBottom: !showDeviceName ? cardHeaderPadding / 2 : 2,
            }}
          >
            <div>
              {showDeviceName && (
                <Typography
                  style={{
                    fontSize: deviceNameFontsize,
                    paddingBottom: cardHeaderPadding / 2,
                  }}
                  variant="h3"
                  noWrap
                >
                  {data.device?.name || <div>&nbsp;</div>}
                </Typography>
              )}
              {showSubjectId && (
                <Typography
                  style={{
                    fontSize: subjectFontSize,
                    lineHeight: `${100 + (tileScale / 100) * 30}%`,
                    paddingTop: !showDeviceName ? cardHeaderPadding / 2 : 0,
                  }}
                  variant="subtitle1"
                  noWrap
                >
                  {data.subject?.identifier || <div>&nbsp;</div>}
                </Typography>
              )}
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ display: "flex", gap: cardHeaderPadding / 2 }}>
              {deviationAlerts?.movement && (
                <RenderAlert metric={deviationAlerts.movement} />
              )}
              {deviationAlerts?.respiration_rate && (
                <RenderAlert metric={deviationAlerts.respiration_rate} />
              )}
              {deviationAlerts?.time_asleep && (
                <RenderAlert metric={deviationAlerts.time_asleep} />
              )}
            </div>
          </CardContent>
          <CardContent
            style={{ padding: cardHeaderPadding }}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              borderTop:
                showDeviceName || showSubjectId
                  ? "1px solid rgba(0, 0, 0, 0.12)"
                  : "none",
              borderBottom:
                showDuration || showVitals
                  ? "1px solid rgba(0, 0, 0, 0.12)"
                  : "none",
            }}
          >
            {showIcon && (
              <Avatar
                style={{ width: avatarScale, height: avatarScale }}
                src={getMonitorTileStatusIcon(data.state)}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: showIcon ? "5%" : 0,
                flexDirection: "column",
              }}
            >
              <Typography
                style={{ fontSize: statusFontSize, fontWeight: "500" }}
                variant="h4"
              >
                {getMonitorTileStatusText(data.state)}
              </Typography>
              {showDuration && showVitals && (
                <Typography
                  style={{ fontSize: durationFontSize }}
                  variant="h6"
                  fontWeight="400"
                >
                  {durationText}
                </Typography>
              )}
            </div>
          </CardContent>

          {showVitals && (
            <CardActions
              style={{ paddingLeft: cardHeaderPadding }}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                padding: `${cardActionPadding}px`,
              }}
            >
              {/* to fix height */}
              {isDisabled && (
                <div style={{ fontSize: footerFontSize }}>&nbsp;</div>
              )}

              {!isDisabled && (
                <Typography
                  style={{ fontSize: footerFontSize }}
                  variant="h5"
                  sx={{ width: "50%" }}
                  fontWeight="normal"
                >
                  {data.state !== "not_in_bed" && data.metrics
                    ? getMonitorMovementText(data.metrics.latest_movement_mean)
                    : ""}
                </Typography>
              )}

              {!isDisabled && (
                <Typography
                  style={{ fontSize: footerFontSize }}
                  variant="h5"
                  sx={{ width: "25%" }}
                  fontWeight="normal"
                >
                  {data.metrics && data.metrics.latest_spo2_mean ? (
                    `${t("spo2")}: ${Math.round(data.metrics.latest_spo2_mean)}`
                  ) : (
                    <div style={{ fontSize: footerFontSize }}>&nbsp;</div>
                  )}
                </Typography>
              )}
              {!isDisabled && (
                <Typography
                  style={{ fontSize: footerFontSize }}
                  variant="h5"
                  sx={{ width: "25%" }}
                  fontWeight="normal"
                >
                  <span style={{ float: "right" }}>
                    {`${t("rr")}: ${
                      data.metrics?.respiration_rate_pooled_mean
                        ? Math.round(data.metrics.respiration_rate_pooled_mean)
                        : "-"
                    }`}
                  </span>
                  {/* )} */}
                </Typography>
              )}
            </CardActions>
          )}

          {!showVitals && showDuration && (
            <CardActions style={{ padding: cardHeaderPadding }}>
              <Typography
                style={{ fontSize: footerFontSize }}
                variant="caption"
                fontWeight="normal"
              >
                {t("Duration")}: {durationText}
              </Typography>
            </CardActions>
          )}

          {showEpochTimestamp && (
            <Box
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                padding: "5px 16px",
              }}
            >
              {epochText}
            </Box>
          )}
        </MonitorCardStyled>
      </Tooltip>
    </Box>
  );
};
export default memo(LiveMonitorTile);
