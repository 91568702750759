import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";

import { LANG_KEY } from "../utils/localStorage.js";
// English variatns
import en from "./lang/en/en.json";
// Deutch variatns
import de from "./lang/de/de.json";
// Norsk variatns
import nb_healthwelfare from "./lang/nb/nb-health-welfare.json";
import nb_private_customer from "./lang/nb/nb-private-customer.json";
import nb_sleep_research from "./lang/nb/nb-sleep-research.json";

// language resources
export const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  nb_healthwelfare: {
    translation: nb_healthwelfare,
  },
  nb_sleep_research: {
    translation: nb_sleep_research,
  },
  nb_private_customer: {
    translation: nb_private_customer,
  },
};

// lanugage detector class
const languageDetector = new LanguageDetector();
// language detection options.
const langDetectionOptions = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: LANG_KEY,
  lookupLocalStorage: LANG_KEY,
  lookupSessionStorage: LANG_KEY,
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

// language init
// language detection service to detect the language of the browser.

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: langDetectionOptions,
    debug: true,
    initImmediate: false,
    preload: ["nb_healthwelfare", "en", "de"],
    keySeparator: false,
    fallbackLng: "en",
    resources,
    lng: window.localStorage.LANG_KEY || "en" || "de",
  });
export default i18n;
