import {
  Checkbox,
  FormControlLabel,
  Grid,
  checkboxClasses,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { Controller, useFormContext } from "react-hook-form";

import { ITileSettings } from "@modules/monitor/DashboardScreen";
import SMPopoverDialog from "components/SMPopover.tsx/PopoverDialog";
import { Dispatch, SetStateAction } from "react";

const FormControlLabelStyled = styled(FormControlLabel)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    fontSize: 16,
    padding: 6,
    margin: 0,
    textTransform: "none",
    "& .MuiFormControlLabel-label": {
      fontSize: 16,
    },
    [`& .${checkboxClasses.root}`]: {
      color: theme.palette.main[70],
      width: 12,
      height: 12,
      marginLeft: 1,
      marginRight: 10,
      p: 0,
      borderRadius: 0,
    },
  };
});

function SupportSettingsPopover({
  showHiddenSettings,
  setShowHiddenSettings,
}: {
  showHiddenSettings: boolean;
  setShowHiddenSettings: Dispatch<SetStateAction<boolean>>;
}) {
  const { control } = useFormContext<ITileSettings>();
  return (
    <SMPopoverDialog
      open={showHiddenSettings}
      title="Tile Settings"
      maxWidth="sm"
      onClose={() => setShowHiddenSettings(false)}
    >
      <Grid container rowSpacing={2} padding={1.5} sx={{ paddingTop: 0 }}>
        <Grid item xs={12} md={12}>
          <FormControlLabelStyled
            control={
              <Controller
                name="show_epoch_timestamp"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Show latest epoch timestamp delta"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControlLabelStyled
            checked
            control={
              <Controller
                name="show_tile_warning"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Show warning if data is old (latest epoch timestamp delta  > 3 mins)"
          />
        </Grid>
      </Grid>
    </SMPopoverDialog>
  );
}
export default SupportSettingsPopover;
