import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

import { ReactComponent as SortAscIcon } from "assets/icons/new/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/new/sort-desc.svg";
import { ReactComponent as UnsortedIcon } from "assets/icons/new/unsorted-icon.svg";
import useFixedHeader from "hooks/UseFixedHeader";
import {
  createContext,
  MouseEvent,
  ReactNode,
  RefObject,
  useState,
} from "react";
import { IconButtonStyled } from "styled/CommonStyled";
import * as LOCAL_STORAGE from "utils/localStorage";

/* *****************************************************
 *                STYLED COMPONETS
 * ***************************************************** */
export const TableRowStyled = styled(TableRow)(
  ({
    disable_row_hover,
    dynamic_padding,
  }: {
    disable_row_hover: number;
    dynamic_padding?: number;
  }) => {
    const theme = useTheme();
    return {
      padding: "16px 20px",
      fontSize: 14,
      cursor: disable_row_hover ? "default" : "pointer",
      textAlign: "left",
      background: theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      color: theme.palette.neutral[40],
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        verticalAlign: "top",
      },
      "&:last-child": {
        borderBottom: "none",
      },
      "& td": {
        color: theme.palette.neutral[40],
        fontSize: 16,
        [theme.breakpoints.down("lg")]: {
          padding: dynamic_padding ? "16px 5px" : "16px",
        },
      },
      "& td:first-of-type": {
        color: theme.palette.neutral[70],
        fontSize: 16,
      },
      "&:hover": {
        background: disable_row_hover ? "none" : theme.palette.main[2],
      },
    };
  },
);

export const TableHeadRowStyled = styled(TableRowStyled)(
  ({ dynamic_padding }: { dynamic_padding?: number }) => {
    const theme = useTheme();
    return {
      textTransform: "uppercase",
      textAlign: "left",
      verticalAlign: "top",
      background: theme.palette.neutral[2],
      fontWeight: "normal",
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      cursor: "default",
      // boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
      "& th": {
        fontWeight: "normal",
        fontSize: 14,
        color: theme.palette.neutral[50],
        [theme.breakpoints.down("lg")]: {
          padding: dynamic_padding ? "16px 5px" : "16px",
        },
      },

      [`& .${tableCellClasses.root}`]: {
        border: "none",
      },
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      },
      "&:hover": {
        background: theme.palette.neutral[2],
      },
    };
  },
);

export const TableHeadDataStyled = styled("div")(
  ({
    cursordefault,
    txtcenter,
  }: {
    cursordefault?: number;
    txtcenter?: number;
  }) => {
    return {
      cursor: cursordefault ? "default" : "pointer",
      "& > div": {
        display: "flex",
        verticalAlign: "middle",
        columnGap: 5,
        justifyContent: txtcenter ? "center" : "none",
        textAlign: txtcenter ? "center" : "left",
      },
      "& > div:nth-of-type(2)": {
        marginLeft: 10,
      },
    };
  },
);

export const KeyContext = createContext(false);

/**
 * Sort order
 */
type Order = "asc" | "desc";

/**
 * Interface HeadCell
 *
 * Interface for the table HeadCells
 * @props `id` should be property name of the table row, use to sort dynamically.
 * @props  `label` String to render as table head cell
 *
 */
interface HeadCell<IRowData> {
  id: keyof IRowData;
  label: string;
  hideSort?: boolean;
  textCenter?: boolean;
  hideColumn?: boolean;
}

/**
 * Interface IProps
 *
 * Interface for the table rows
 * @props `rows` Table row data of type IRowData [generic]
 * @props `headCells` Table row data of type
 * @props `stylesProp` styles object as [rowKey]:styles, Inorder to apply special styling for certan rows
 * @props `renderComponentForValue` object as [rowKey]:JSX.Element, Render component passed instead of row value,
 */
interface IProps<IRowData> {
  rows: IRowData[];
  headCells: HeadCell<IRowData>[];
  onRowClick: (event: MouseEvent<unknown>, row: IRowData) => void;
  stylesPropData?: { [key: string]: { [key: string]: string } };
  stylesPropHead?: { [key: string]: { [key: string]: string } };
  renderComponentForValue?: { [key: string]: (label: any) => JSX.Element };
  disable_row_hover?: boolean;
  dynamic_padding?: boolean;
  minWidth?: number;
  defaultOrderBy?: keyof IRowData;
  localSettingKey?: string;
}

/**
 * Enhanced Interface for table head
 *
 * @props `onRequestSort` function to request sort for the cicked column
 * @props `onRequestSort` sort type - ASC || DESC
 * @props `orderBy`  sort property
 * @props `renderComponentForValue` object as [rowKey]:JSX.Element, Render component passed instead of row value,
 */
interface EnhancedTableProps<IRowData> {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IRowData) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell<IRowData>[];
  stylesPropHead?: { [key: string]: { [key: string]: string } };
  dynamic_padding?: number;
  headerRef?: RefObject<HTMLTableSectionElement>;
}

/* *****************************************************
 *               Dynamic Sorting
 * ***************************************************** */

/**
 * Sort function
 * this function do item sort
 *
 * @returns sort return
 */
function descendingComparator<T>(a: T, b: T, orderBy: keyof T | "") {
  if (!orderBy) {
    return 0;
  }
  const leftVal = b[orderBy];
  const rightVal = a[orderBy];

  // if the left and right is array
  if (Array.isArray(rightVal) && Array.isArray(leftVal)) {
    if (
      typeof leftVal[leftVal.length - 1] === "string" &&
      typeof rightVal[rightVal.length - 1] === "string"
    ) {
      return Intl.Collator("nb", { sensitivity: "base" }).compare(
        leftVal[leftVal.length - 1],
        rightVal[rightVal.length - 1],
      );
    }
    if (leftVal[leftVal.length - 1] < rightVal[rightVal.length - 1]) {
      return -1;
    }
    if (leftVal[leftVal.length - 1] > rightVal[rightVal.length - 1]) {
      return 1;
    }
  }

  // if the left and right is string
  if (typeof leftVal === "string" && typeof rightVal === "string") {
    return Intl.Collator("nb", { sensitivity: "base" }).compare(
      leftVal,
      rightVal,
    );
  }

  if (leftVal < rightVal) {
    return -1;
  }
  if (leftVal > rightVal) {
    return 1;
  }
  return 0;
}

/**
 * Function to check the type of operation
 *
 * @returns `descendingComparator` function
 *
 */
function getComparator<IRowData>(
  order: Order,
  orderBy: keyof IRowData | undefined,
): (a: IRowData, b: IRowData) => number {
  return order === "desc"
    ? (a, b) => descendingComparator<IRowData>(a, b, orderBy || "")
    : (a, b) => -descendingComparator<IRowData>(a, b, orderBy || "");
}

/**
 * SMTableHead
 *
 * This function will render the table head row based on the [HeadCell]s.
 * `onRequestSort` will be called on every column click
 *
 * @props `EnhancedTableProps` Props of type EnhancedTableProps
 */
function SMTableHead<IRowData>(props: EnhancedTableProps<IRowData>) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    stylesPropHead,
    dynamic_padding,
    headerRef,
  } = props;
  const createSortHandler =
    (property: keyof IRowData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead ref={headerRef}>
      <TableHeadRowStyled
        disable_row_hover={0}
        dynamic_padding={dynamic_padding}
      >
        {headCells
          .filter((headCell: HeadCell<IRowData>) => !headCell.hideColumn)
          .map((headCell: HeadCell<IRowData>) => (
            <TableCell
              key={headCell.label}
              sortDirection={orderBy === headCell.id ? order : false}
              style={
                stylesPropHead
                  ? stylesPropHead[`${headCell.id.toString()}`]
                  : undefined
              }
            >
              <TableHeadDataStyled
                onClick={
                  !headCell.hideSort ? createSortHandler(headCell.id) : () => {}
                }
                cursordefault={headCell.hideSort ? 1 : 0}
                txtcenter={headCell.textCenter ? 1 : 0}
              >
                <div>
                  <div> {headCell.label} </div>
                  {!headCell.hideSort && (
                    <IconButtonStyled padding={0} width="16px">
                      {orderBy === headCell.id ? (
                        order === "asc" ? (
                          <SortAscIcon />
                        ) : (
                          <SortDescIcon />
                        )
                      ) : (
                        <UnsortedIcon />
                      )}
                    </IconButtonStyled>
                  )}
                </div>
              </TableHeadDataStyled>
            </TableCell>
          ))}
      </TableHeadRowStyled>
    </TableHead>
  );
}

/**
 * SMDynamicTable
 *
 * This function will render the table rows based on the RowData array of type `IRowData`
 * @param `IRowData` Interface of the rowdata
 * @props `IProps` Props of type IProps
 */
export default function SMDynamicTable<IRowData>({
  headCells,
  rows,
  onRowClick,
  stylesPropData,
  stylesPropHead,
  renderComponentForValue,
  disable_row_hover,
  dynamic_padding,
  minWidth,
  defaultOrderBy,
  localSettingKey,
}: IProps<IRowData>) {
  const settings = LOCAL_STORAGE.getAdminSettings() || {};
  const localSetting = settings[localSettingKey || ""] || {};
  const [order, setOrder] = useState<Order>(localSetting.direction || "asc");
  const [orderBy, setOrderBy] = useState<keyof IRowData | undefined>(
    localSetting.key || defaultOrderBy,
  );

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IRowData,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (localSettingKey) {
      // set local storage
      const currentSettings = LOCAL_STORAGE.getAdminSettings();
      LOCAL_STORAGE.setAdminSettings({
        ...currentSettings,
        [localSettingKey]: {
          key: property,
          direction: isAsc ? "desc" : "asc",
        },
      });
    }
  };

  const generateKey = (ind: number) => {
    return ind + 1000;
  };

  const { headerRef, tableWrapperRef, lastChildRef } = useFixedHeader();

  return (
    <Box sx={{ width: "100%" }} ref={tableWrapperRef}>
      <TableContainer>
        <Table sx={{ minWidth: minWidth || 750 }}>
          <SMTableHead<IRowData>
            key="TableHead"
            order={order}
            orderBy={orderBy as string}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            stylesPropHead={stylesPropHead}
            dynamic_padding={dynamic_padding ? 1 : 0}
            headerRef={headerRef}
          />
          <TableBody key="TableBody">
            {rows
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row: IRowData, index: number) => (
                <TableRowStyled
                  onClick={(event) =>
                    onRowClick ? onRowClick(event, row) : null
                  }
                  disable_row_hover={disable_row_hover ? 1 : 0}
                  tabIndex={-1}
                  dynamic_padding={dynamic_padding ? 1 : 0}
                  key={index}
                  ref={index === rows.length - 1 ? lastChildRef : null}
                >
                  {
                    // @ts-ignore
                    (Object.keys(row) as Array<keyof IRowData>)
                      .filter(
                        (key: keyof IRowData) =>
                          `${key.toString()}`.charAt(0) !== "_",
                      )
                      .map((key: keyof IRowData, ind: number) => {
                        if (
                          renderComponentForValue &&
                          renderComponentForValue[`${key.toString()}`]
                        ) {
                          return (
                            <TableCell
                              key={generateKey(ind)}
                              style={
                                stylesPropData
                                  ? stylesPropData[`${key.toString()}`]
                                  : undefined
                              }
                            >
                              <KeyContext.Provider
                                key={generateKey(ind)}
                                value={true}
                              >
                                {renderComponentForValue[`${key.toString()}`](
                                  row[key],
                                )}
                              </KeyContext.Provider>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={generateKey(ind)}
                            style={
                              stylesPropData
                                ? stylesPropData[`${key.toString()}`]
                                : undefined
                            }
                          >
                            <KeyContext.Provider
                              key={generateKey(ind)}
                              value={true}
                            >
                              {row[key] as ReactNode}
                            </KeyContext.Provider>
                          </TableCell>
                        );
                      })
                  }
                </TableRowStyled>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
